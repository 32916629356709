var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"max-w-full sm:max-w-md mx-auto inset-y-0 right-0 full-screen-height flex flex-col justify-center items-center"},[_c('div',{staticClass:"s-height shadow bg-white full-screen-height w-full relative sm:rounded-lg"},[_c('div',{ref:"swipeContainer",staticClass:"swipeable-container"},[(_vm.currentScreen !== 0)?_c('div',{staticClass:"tap-zone left-zone",on:{"click":_vm.goBackward}}):_vm._e(),(_vm.currentScreen !== 0)?_c('div',{staticClass:"tap-zone right-zone",on:{"click":_vm.goForward}}):_vm._e(),(_vm.currentScreen == 0)?_c('login',{on:{"registerProp":_vm.handleProp}}):_vm._e(),(_vm.currentScreen == 1)?_c('cover'):_vm._e(),(_vm.currentScreen == 2)?_c('accountDetails',{attrs:{"reviewData":{
                monthly_average_balance: _vm.review.monthly_average_balance,
                month_with_highest_balance: _vm.review.month_with_highest_balance
            }}}):_vm._e(),(_vm.currentScreen == 3)?_c('targetSavings',{attrs:{"reviewData":{
                total_savings_amount: _vm.review.total_savings_amount,
                total_savings_returns: _vm.review.total_savings_returns,
                top_savings_category: _vm.review.top_savings_category,
            }}}):_vm._e(),(_vm.currentScreen == 4)?_c('deposit',{attrs:{"reviewData":{
                total_deposits: _vm.review.total_deposits,
                average_amount_deposited: _vm.review.average_amount_deposited,
                top_depositor_name: _vm.review.top_depositor_name,
                total_amount_sent_by_top_depositor: _vm.review.total_amount_sent_by_top_depositor,
            }}}):_vm._e(),(_vm.currentScreen == 5)?_c('transfers',{attrs:{"reviewData":{
                total_outgoing_transfers: _vm.review.total_outgoing_transfers,
                top_recipient_name: _vm.review.top_recipient_name,
                count_of_transfers_from_top_recipient: _vm.review.count_of_transfers_from_top_recipient,
                total_amount_sent_by_top_recipient: _vm.review.total_amount_sent_by_top_recipient
            }}}):_vm._e(),(_vm.currentScreen == 6)?_c('loans',{attrs:{"reviewData":{
                loans_collected: _vm.review.loans_collected,
                loans_repaid: _vm.review.loans_repaid,
                has_been_rejected_on_all_loans: _vm.review.has_been_rejected_on_all_loans,
            }}}):_vm._e(),(_vm.currentScreen == 7)?_c('investment',{attrs:{"reviewData":{
                total_amount_invested: _vm.review.total_amount_invested,
                total_investment_returns: _vm.review.total_investment_returns
            }}}):_vm._e(),(_vm.currentScreen == 8)?_c('loanFriends',{attrs:{"reviewData":{
                total_amount_lent: _vm.review.total_amount_lent,
                favorite_beneficiary: _vm.review.favorite_beneficiary,
                total_amount_borrowed: _vm.review.total_amount_borrowed,
                favorite_lender: _vm.review.favorite_lender
            }}}):_vm._e(),(_vm.currentScreen == 9)?_c('billPayments',{attrs:{"reviewData":{
                    count_of_bill_payments: _vm.review.count_of_bill_payments,
                    amount_spent_on_bill_payments: _vm.review.amount_spent_on_bill_payments,
                    top_biller_category: _vm.review.top_biller_category,
                    total_amount_paid_for_top_biller_category: _vm.review.total_amount_paid_for_top_biller_category
                }}}):_vm._e(),(_vm.currentScreen == 10)?_c('walletInterest',{attrs:{"reviewData":{
                wallet_interest: _vm.review.wallet_interest,
                cashback: _vm.review.cashback
            }}}):_vm._e(),(_vm.currentScreen == 11)?_c('financialRecommendations',{attrs:{"reviewData":{
                    total_amount_invested: _vm.review.total_amount_invested,
                    total_outgoing_transfers: _vm.review.total_outgoing_transfers,
                    total_savings_amount: _vm.review.total_savings_amount,
                    has_more_than_two_due_schedules: _vm.review.has_more_than_two_due_schedules,
                    total_deposits: _vm.review.total_deposits,
                    total_amount_lent: _vm.review.total_amount_lent,
                    total_amount_borrowed: _vm.review.total_deposits
                }}}):_vm._e(),(_vm.currentScreen == 12)?_c('superStar'):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }