<template>
    <div class="transfers">
        <div class="mt-1">
            <pagination :tab="5" class="m-auto" />
        </div>

        <div class="flex mt-5 priority-z-index mx-9">
            <SycamoreLogo />
        </div>

        <div class="mt-11 text-center">
            <p class="text-lg font-satoshi_r">You sent a total of</p>
            <p class="text-4xl font-satoshi_b">₦{{ reviewData.total_outgoing_transfers?
                        Number(reviewData.total_outgoing_transfers.toFixed(2)).toLocaleString() : 0}}</p>
        </div>

        <div v-if="reviewData.total_outgoing_transfers >= 1000" class="flex justify-center items-center mt-4">
            <p class="bg-green-84 rounded-full text-center text-sm font-satoshi_m px-2 py-2">
                {{reviewData.top_recipient_name? reviewData.top_recipient_name.split(" ").map((n)=>n[0]).slice(0, 2).join("") : "NO"}}</p>
            <p class="text-xs font-satoshi_r">
                <span class="ml-2 mr-1 font-satoshi_b">{{reviewData.top_recipient_name? reviewData.top_recipient_name.split(" ")[0] : "Nobody"}}</span>was your best
                buddy
            </p>
        </div>

        <div class="flex justify-center w-full mt-10">
            <img v-if="reviewData.total_outgoing_transfers <= 0" src="../../assets/images/year-in-review/plane1.svg" />
            <img v-else src="../../assets/images/year-in-review/plane.svg" />
        </div>

        <div class="mt-20 mx-9">
            <div v-if="reviewData.total_outgoing_transfers <= 0">
                <p class="font-satoshi_b text-3xl">Shhh...</p>
                <p class="font-satoshi_r text-lg pt-3">We've got a secret. Sycamore transfers are the quickest way to send money, even faster than a ninja.</p>
                <p class="font-satoshi_r text-lg pt-4">Try it out and see for yourself.</p>
            </div>

            <div v-else-if="reviewData.total_outgoing_transfers < 1000">
                <p class="font-satoshi_r text-lg">Your 2024 transfers were under ₦1000 😔. We know you've got more to give! Let's see those transfer numbers skyrocket in 2025.</p>
                <p class="font-satoshi_r text-lg pt-5">Your friends and family will thank you (and so will we).</p>
            </div>

            <div v-else>
                <div>
                    <p class="font-satoshi_r text-lg">You transferred to them</p>
                    <p class="font-satoshi_b text-3xl">{{reviewData.count_of_transfers_from_top_recipient? reviewData.count_of_transfers_from_top_recipient : 0}} times</p>
                </div>
                <div class="mt-8">
                    <p class="font-satoshi_r text-lg">A total of</p>
                    <p class="font-satoshi_b text-3xl">₦{{ reviewData.total_amount_sent_by_top_recipient?
                            Number(reviewData.total_amount_sent_by_top_recipient.toFixed(2)).toLocaleString() : 0}}</p>
                </div>
            </div>

            <!-- <div v-if="reviewData.total_outgoing_transfers < 1000" class="mt-12 px-16">
                <a href="https://sycamoreng.onelink.me/Qthc/9lrl1bvq" class="priority-z-index">
                    <button type="button" class="text-sm" >
                        Send Money
                    </button>
                </a>
            </div> -->
        </div>
    </div>
</template>

<script>
import SycamoreLogo from "../../svg/SycamoreLogo.vue";
import pagination from "./pagination.vue";

export default {
    components: {
        SycamoreLogo,
        pagination
    },
    props: {
        reviewData: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped>
.transfers {
    padding: 16px 0px;
    background-color: #4286f5;
    color: #f6f7ff;
    min-height: 100vh;
    background-image: url("../../assets/images/year-in-review/transfers-bg.png");
    background-size: cover;
}

a button{
    outline: none;
    border-radius: 12px;
    height: 48px;
    color: #fff;
    font-family: 'satoshi_m', 'sans-serif';
    width: 100%;
    background-color: #1D4052;
}
</style>