<template>
    <div class="bill-payment">
        <div class="mt-1">
            <pagination :tab="9" class="m-auto"/>
        </div>
        <div class="flex mt-5 mx-9 priority-z-index">
            <SycamoreLogo />
        </div>
        <div v-if="Number(reviewData.count_of_bill_payments) > 0" class="mt-12 mx-8">
            <div>
                <p class="font-satoshi_r text-lg">You paid bills</p>
                <p class="font-satoshi_b text-4xl mt-1">
                    {{reviewData.count_of_bill_payments? reviewData.count_of_bill_payments : 0}} times</p>
            </div>
            <div class="mt-1.5">
                <p class="font-satoshi_r text-lg">in 2024, with a total of</p>
                <p class="font-satoshi_b text-4xl mt-1">
                    ₦{{ reviewData.amount_spent_on_bill_payments?
                    Number(reviewData.amount_spent_on_bill_payments.toFixed(2)).toLocaleString() :0 }}</p>
            </div>
        </div>

        <div v-else class="mt-12 mx-8">
            <div>
                <p class="font-satoshi_r text-lg">You paid</p>
                <p class="font-satoshi_b text-4xl mt-1">
                    {{reviewData.count_of_bill_payments? reviewData.count_of_bill_payments : 0}} bills</p>
                <p class="font-satoshi_r text-lg">on Sycamore.</p>
            </div>

            <div style="padding-top: 1.85rem;">
                <p class="font-satoshi_r text-sm">Pay your bills with ease on Sycamore and enjoy a stress-free life.</p>
            </div>
        </div>

        <div class="flex justify-center w-full mt-20 px-8">
            <img v-if="reviewData.count_of_bill_payments <= 0" src="../../assets/images/year-in-review/bills1.svg" />
            <img v-else src="../../assets/images/year-in-review/bills.svg" />
        </div>

        <!-- <div v-if="reviewData.amount_spent_on_bill_payments <= 0" class="mt-20 px-24">
            <a href="https://sycamoreng.onelink.me/Qthc/alhb6svn" class="priority-z-index">
                <button type="button" class="text-sm" >
                    Pay Bills
                </button>
            </a>
        </div> -->

        <div v-if="reviewData.amount_spent_on_bill_payments > 0" class="mx-8">
            <p v-if="reviewData.amount_spent_on_bill_payments < 10000" class="mt-14 text-lg">
                You've made a great start! Keep paying your bills with Sycamore and enjoy convenience. Let's aim to pay even more next year💪🏽
            </p>

            <p v-else class="mt-14 text-lg">
                Your top biller was
                <span class="font-satoshi_b">
                    {{reviewData.top_biller_category? reviewData.top_biller_category : "No biller"}}</span>, with a total of
                <span class="font-satoshi_b">
                    ₦{{reviewData.total_amount_paid_for_top_biller_category?
                    Number(reviewData.total_amount_paid_for_top_biller_category.toFixed(2)).toLocaleString() : 0 }}</span> paid to this biller.
            </p>

            <!-- <div v-if="reviewData.amount_spent_on_bill_payments < 10000" class="mt-9 px-16">
                <a href="https://sycamoreng.onelink.me/Qthc/alhb6svn">
                    <button type="button" class="text-sm">
                        Explore More Billers
                    </button>
                </a>
            </div> -->
        </div>
    </div>
</template>

<script>
import SycamoreLogo from "../../svg/SycamoreLogo.vue";
import pagination from "./pagination.vue";

export default {
    components: {
        SycamoreLogo,
        pagination
    },
    props: {
        reviewData: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped>
.bill-payment {
    padding: 1rem 0rem 2rem;
    color: #015b25;
    background-color: #d3ef98;
    min-height: 100vh;
    background-image: url("../../assets/images/year-in-review/bills-payment-bg.png");
    background-size: cover;
}

a button{
    outline: none;
    border-radius: 12px;
    height: 48px;
    color: #d3ef98;
    font-family: 'satoshi_m', 'sans-serif';
    width: 100%;
    background-color: #015B25;
}

</style>