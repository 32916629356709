<template>
    <div class="login">
        <loader v-if="isLoading"  />

        <form v-if="!isLoading && parmasKey !== 'id'" @submit.prevent="fetchReviewWithEmail()">
            <div class="h-v-max">
                <div class="flex mt-8">
                    <img src="../../assets/images/year-in-review/blue-logo.svg" />
                </div>

                <div class="mt-12">
                    <p class="font-satoshi_b text-lg">Let’s make sure it’s you...</p>
                    <p class="font-satoshi_r mt-1 text-lg">Sign in to see your Money Recap.</p>
                </div>

                <div class="mt-5">
                    <label for="email">Email</label><br>
                    <input v-model="email" id="email"/>
                </div>
                <div class="mt-3">
                    <label for="password">Password</label><br>
                    <input v-model="password" id="password" type="password"/>
                </div>
            </div>

            <div v-if="!isLoading" class="button-container">
                <button type="submit" class="flex items-center justify-center gap-2 bg-gray07">
                    Check your Money Recap 
                    <img src="../../assets/images/year-in-review/ArrowUpRight.svg" alt="">
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { fetchwithId, fetchWithEmail } from "@/api/review";
import { errorNotification } from "@/plugins/notifier";
import loader from "@/components/loader";
import TokenInvalid from "@/components/TokenInvalid.vue";

export default {
    components: {
        loader,
        TokenInvalid
    },

    data() {
        return {
            email: "",
            password: "",
            isLoading: false,

            parmasKey: "",
            parmasValue: ""
        }
    },

    methods: {
        async fetchReviewWithId() {
            try {
                const response = await fetchwithId(`${this.parmasValue}`);
                this.$emit( "registerProp", response.data.data)

            } catch (err) {
                errorNotification(`${err.response.data.message}, sign in with email and password to continue`);
                this.parmasKey = "email"

            } finally {
                this.isLoading = false;
            }
        },

        async fetchReviewWithEmail() {
            this.isLoading = true;

            try {
                const response = await fetchWithEmail({
                    email: this.email,
                    password: this.password
                });

                this.$emit( "registerProp", response.data.data);


            } catch (err) {
                errorNotification(err.response.data.message);

            } finally {
                this.isLoading = false;

            }
        },

        checkRouteQuery() {
            const query = this.$route.query;
            this.isLoading = true; 

            if (!Object.keys(query).length) {
                this.parmasKey = "email"
                this.isLoading = false;
                return
                
            } else if (query.id) {
                this.parmasKey = "id"
                this.parmasValue = query.id
                this.fetchReviewWithId()

            } else if (query.email) {
                this.email = query.email
                this.parmasKey = "email"
                this.parmasValue = query.email
                this.isLoading = false;
                return

            } else {
                errorNotification("something went wrong. Please refresh page.")

            }
        },
    },

    created(){
        this.checkRouteQuery()
    }
};
</script>

<style scoped>
.login {
    height: 100dvh;
    background-color: #fff !important;
    color: #073042 !important;
    position: relative;
}

.h-v-max{
    padding: 31px 51px 0px;
}

input{
   width: 100%;
   border-radius: 12px;
   padding: 12px;
   border: 0.5px solid #C6C5CA;
   line-height: 24px;
   font-family: 'satoshi_r', 'sans-serif';
   outline: none;
}

input:focus{
   outline: none;
}

label{
   font-family: 'satoshi_m', 'sans-serif';
   display: inline-flex;
   padding-bottom: 4px;
   font-size: 14px;
}

.button-container{
    position: absolute;
    bottom: 60px;
    width: 100%;
    padding: 0px 51px;
}

button{
    outline: none;
    border-radius: 12px;
    height: 56px;
    color: #ffffff;
    font-family: 'satoshi_m', 'sans-serif';
    width: 100%;
}
</style>