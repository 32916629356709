<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.0671 8.3086L12.8171 2.05859C12.7298 1.97113 12.6184 1.91155 12.4972 1.88736C12.376 1.86318 12.2503 1.8755 12.1361 1.92274C12.0218 1.96999 11.9242 2.05005 11.8555 2.15281C11.7867 2.25556 11.75 2.37638 11.75 2.5V5.65234C9.72339 5.82578 7.48511 6.81797 5.6437 8.37969C3.42651 10.2609 2.04605 12.6852 1.7562 15.2055C1.73355 15.4014 1.77328 15.5995 1.86974 15.7716C1.96619 15.9436 2.11446 16.0809 2.29343 16.1638C2.47241 16.2467 2.67298 16.271 2.86659 16.2334C3.06021 16.1957 3.23701 16.0979 3.37183 15.9539C4.2312 15.0391 7.28901 12.1461 11.75 11.8914V15C11.75 15.1236 11.7867 15.2444 11.8555 15.3472C11.9242 15.4499 12.0218 15.53 12.1361 15.5773C12.2503 15.6245 12.376 15.6368 12.4972 15.6126C12.6184 15.5885 12.7298 15.5289 12.8171 15.4414L19.0671 9.19141C19.184 9.07424 19.2497 8.9155 19.2497 8.75C19.2497 8.5845 19.184 8.42576 19.0671 8.3086ZM13 13.4914V11.25C13 11.0842 12.9341 10.9253 12.8169 10.8081C12.6997 10.6908 12.5407 10.625 12.375 10.625C10.1812 10.625 8.04448 11.1977 6.02417 12.3281C4.99522 12.9064 4.03652 13.6017 3.16714 14.4C3.62026 12.5375 4.76245 10.7664 6.4523 9.33281C8.26636 7.79453 10.4804 6.875 12.375 6.875C12.5407 6.875 12.6997 6.80915 12.8169 6.69194C12.9341 6.57473 13 6.41576 13 6.25V4.00938L17.7414 8.75L13 13.4914Z" fill="#282828"/>
</svg>

</template>

<script>
export default {

}
</script>

<style>

</style>