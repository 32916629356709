import sycamoreServiceClient from '.';

export const fetchwithId = async id => {
    const response = await sycamoreServiceClient({
        url: `/customer/year-in-review?id=${Number(id)}`, 
        method: 'post',
    });

    return {
        status: response.status,
        data: response.data,
    };
};

export const fetchWithEmail = async (body) => {
    try {
        const response = await sycamoreServiceClient({
            url: "/customer/year-in-review", 
            method: "post",
            data: body,
            headers: {
                "Content-Type": "application/json",
            },
        });

        return Promise.resolve({
            status: response.status,
            data: response.data,
        });
    } catch (error) {
        return Promise.reject(error);
    }
};
