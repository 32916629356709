<template>
    <div class="account-balance">
        <div class="">
            <div class="mt-1 md:mt-20">
                <pagination :tab="2" class="m-auto" />
            </div>
            <div class="flex mt-5 mx-9 priority-z-index">
                <SycamoreLogo />
            </div>
        </div>

        <div class="mt-12 text-center mx-9">
            <p class="text-lg font-satoshi_r">Your average account balance was</p>
            <p class="text-4xl font-satoshi_b">
                ₦{{ reviewData.monthly_average_balance? Number(reviewData.monthly_average_balance.toFixed(2)).toLocaleString() : 0}}
            </p>
        </div>

        <div class="flex justify-center w-full mt-14">
            <img v-if="reviewData.monthly_average_balance <= 0" src="../../assets/images/year-in-review/coin2.svg" />
            <img v-else src="../../assets/images/year-in-review/coin.svg" />
        </div>

        <div v-if="reviewData.monthly_average_balance < 1000" class="px-10">
            <p class="text-center font-satoshi_r" style="line-height: 22px; font-size: 1.1rem;">
                Your balance could use a little boost! A higher balance means more wallet interest earnings.
            </p>

            <!-- <div class="mt-5 px-14">
                <a href="https://sycamoreng.onelink.me/Qthc/9274li8k" class="priority-z-index">
                    <button type="button" class="flex text-sm items-center justify-center gap-2 bg-green-4F">
                        Fund Wallet
                        <img src="../../assets/images/year-in-review/wallet.svg" alt="">
                    </button>
                </a>
            </div> -->
        </div>

        <div v-if="reviewData.monthly_average_balance >= 1000" class="mt-t0">
            <div>
                <graph class="m-auto"/>
                <span class="chart-bars">
                    <span class="bar">{{calculateDisplayedMonths[0]}}</span>
                    <span class="bar">{{calculateDisplayedMonths[1]}}</span>
                    <span class="bar">{{calculateDisplayedMonths[2]}}</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import SycamoreLogo from "../../svg/SycamoreLogo.vue";
import pagination from "./pagination.vue";
import graph from "./graph.vue";

export default {
    components: {
        SycamoreLogo,
        pagination,
        graph
    },
    data() {
        return {
        months: [
            "january", "february", "march", 
            "april", "may", "june", 
            "july", "august", "september", 
            "october", "november", "december"
        ],
        };
    },
    props: {
        reviewData: {
            type: Object,
            required: true
        }
    },

    computed: {
        calculateDisplayedMonths() {
            const highestMonth = this.reviewData?.month_with_highest_balance?.toLowerCase().trim();
            
            const highestIndex = this.months?.indexOf(highestMonth);

            if (highestIndex == 0) {
                return ["December", "January", "February"]
            } else if (highestIndex == 11) {
                return ["November", "December", "January"]

            } else {
                return [
                    this.months[(highestIndex - 1)].charAt(0).toUpperCase() + this.months[(highestIndex - 1)].slice(1).toLowerCase(),
                    this.months[highestIndex].charAt(0).toUpperCase() + this.months[(highestIndex)].slice(1).toLowerCase(),
                    this.months[(highestIndex + 1)].charAt(0).toUpperCase() + this.months[(highestIndex + 1)].slice(1).toLowerCase()
                ];
            }
        }
    }
    
};
</script>

<style>
.account-balance {
    padding: 16px 0px;
    color: #fcfcde;
    background-color: #22884f;
    min-height: 100vh;
    background-image: url("../../assets/images/year-in-review/acct-balance-bg.png");
    background-size: cover;
    background-position: center center;
}

a button{
    outline: none;
    border-radius: 12px;
    height: 48px;
    color: #FCFCDE;
    font-family: 'satoshi_m', 'sans-serif';
    width: 100%;
}

.chart-bars {
  display: flex;
  padding-top: 8px;
  justify-content: space-between;
  width: 337px;
  margin: auto;
}

.bar {
  font-size: 12px;
  flex-direction: column-reverse;
  align-items: center;
  color: #81CA95;
}

</style>