<template>
  <div
    class="w-screen h-screen absolute left-0 top-0 flex justify-center items-center"
  >
    <div
      class="mx-4 md:px-8 py-10 bg-white rounded-2xl component-shadow w-full md:w-4/12"
    >
      <div class="flex justify-center mb-4">
        <img src="@/assets/images/logo_icon.svg" />
      </div>

      <div class="text-center text-black">
        <p class="text-lg font-semibold pb-2">Invalid Token</p>
        <p class="text-sm">
          The token is expired or invalid
        </p>
        <p class="text-sm">Kindly contact <a :href="`mailto:${contacts.contact_email}`" class="underline hover:text-primary text-black">{{ contacts.contact_email }}</a> for support</p>
      </div>
    </div>
  </div>
</template>


<script>
import { getSycamoreContacts } from "@/api/contacts";

export default {
  data() {
    return {
      contacts: null
    };
  },

  methods: {
    async getContacts() {
      const response = await getSycamoreContacts();
      this.contacts = response.data.contact;
    }
  },

  mounted() {
    this.getContacts();
  },
};
</script>


<style scoped>
.component-shadow {
    box-shadow: #e7e7e7  0px 2px 4px 0px, #e7e7e7  0px 4px 12px 4px;
}
</style>