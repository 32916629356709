<template>
    <div class="loanFriends">
        <div class="mt-1">
            <div class="mt-4">
                <pagination :tab="8" class="m-auto" />
            </div>
            <div class="flex mt-5 mx-9 priority-z-index">
                <SycamoreLogo />
            </div>

            <div class="mt-12 text-left mx-9">
                <p class="text-lg font-satoshi_r">You lent</p>
                <p class="text-4xl font-satoshi_b mt-1">
                    ₦{{ reviewData.total_amount_lent?
                    Number(reviewData.total_amount_lent.toFixed(2)).toLocaleString() : 0 }}</p>
                <p class="font-satoshi_r mt-1.5 text-lg">
                    in loans to friends and family this year<span v-if="reviewData.total_amount_lent > 0" class="font-satoshi_r italic mt-1.5 text-lg">, with
                    <span class="font-satoshi_b" style="white-space: nowrap;">
                        {{ reviewData.favorite_beneficiary? reviewData.favorite_beneficiary : "No one" }}</span> being your favourite
                    beneficiary.</span>
                </p>
                
            </div>

            <p v-if="reviewData.total_amount_lent > 0" class="font-satoshi_r mt-9 text-sm pr-8 mx-9">
                You borrowed
                <span class="font-satoshi_b text-green-CB">₦{{ reviewData.total_amount_borrowed?
                    Number(reviewData.total_amount_borrowed.toFixed(2)).toLocaleString() : 0}}</span> from your
                friends and family this year, with
                <span class="text-green-CB font-satoshi_b" style="white-space: nowrap;">{{ reviewData.favorite_lender? reviewData.favorite_lender : "No one" }}</span> being your
                favourite lender.
            </p>

            <p v-else class="font-satoshi_r mt-9 text-sm pr-8 mx-9">
                Help out your loved ones financially without soiling the relationship. Give loans with increased confidence that you’ll get your money back.
            </p>
        </div>

        <div class="flex justify-start w-full mt-10">
            <img v-if="reviewData.total_amount_lent > 0" src="../../assets/images/year-in-review/money-glass.svg" />
            <img v-else src="../../assets/images/year-in-review/money-glass1.svg" />
        </div>
    </div>
</template>

<script>
import SycamoreLogo from "../../svg/SycamoreLogo.vue";
import pagination from "./pagination.vue";

export default {
    components: {
        SycamoreLogo,
        pagination

    },
    props: {
        reviewData: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped>
.loanFriends {
    background-color: #7a44be;
    color: #fff7d8;
    min-height: 100vh;
    background-image: url("../../assets/images/year-in-review/loan-friends-bg.png");
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: cover;
}
</style>