<template>
  <div class="investment pt-4">
    <div>
      <div class="mt-1 md:mt-24">
        <pagination :tab="7" class="m-auto" />
      </div>

      <div class="flex mt-5 mx-9 priority-z-index">
        <SycamoreLogo />
      </div>

      <div class="mt-12 mx-9">
        <div>
          <p class="font-satoshi_r text-lg">You Invested a total of</p>
          <p class="font-satoshi_b text-4xl mt-1">
            ₦{{ reviewData.total_amount_invested?
                        Number(reviewData.total_amount_invested.toFixed(2)).toLocaleString() : 0}}</p>
          <p class="font-satoshi_r text-lg mt-1">in 2024.</p>
        </div>
        <div class="mt-9">
            <div v-if="reviewData.total_amount_invested <= 0">
                <p class="font-satoshi_b text-lg">Ready to grow your money tree?</p>
                <p class="font-satoshi_r text-lg mt-1">
                    Sycamore Investments is the perfect seed to plant. Start small, dream big. Invest today!.
                </p>

                <!-- <div class="mt-8 px-16">
                    <a href="https://sycamoreng.onelink.me/Qthc/i7tv3iac" class="priority-z-index">
                        <button type="button" class="text-sm" >
                            Start Investing
                        </button>
                    </a>
                </div> -->
            </div>

            <div v-else>
                <p class="font-satoshi_r text-lg">Sycamore paid you</p>
                <p class="font-satoshi_b text-4xl mt-1">
                    ₦{{ reviewData.total_investment_returns?
                                Number(reviewData.total_investment_returns.toFixed(2)).toLocaleString() : 0}}</p>
                <p class="font-satoshi_r text-lg mt-1">in Investment returns.</p>
            </div>
        </div>
      </div>
    </div>

    <div class="flex justify-end w-full mt-10 pl-10">
      <img
        v-if="reviewData.total_amount_invested <= 0"
        src="../../assets/images/year-in-review/money-box1.svg"
      />
      <img
        v-else
        src="../../assets/images/year-in-review/money-box.svg"
      />
    </div>
  </div>
</template>
        
<script>
import SycamoreLogo from "../../svg/SycamoreLogo.vue";
import pagination from "./pagination.vue";

export default {
  components: {
    SycamoreLogo,
    pagination
  },
  props: {
        reviewData: {
            type: Object,
            required: true
        }
    }
};
</script>
        
<style scoped>
.investment {
  background-color: #3ddc84;
  min-height: 100vh;
  background-image: url("../../assets/images/year-in-review/investment-bg.png");
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;
}

a button{
    outline: none;
    border-radius: 12px;
    height: 48px;
    color: #3ddc84;
    font-family: 'satoshi_m', 'sans-serif';
    width: 100%;
    background-color: #12542F;
}
</style>