<template>
    <div class="deposit">

        <div class="">
            <div class="mt-1">
                <pagination :tab="4" class="m-auto" />
            </div>
            <div class="flex mt-5 mx-9 priority-z-index">
                <SycamoreLogo />
            </div>
        </div>

        <div class="mt-10 text-center text-green-2B">
            <p class="text-lg font-satoshi_r">Total Amount Deposited</p>
            <p class="text-4xl font-satoshi_b">
                ₦{{ reviewData.total_deposits? Number(reviewData.total_deposits.toFixed(2)).toLocaleString() : 0 }}</p>
        </div>

        <div class="mt-12 mx-4 flex flex-col">
            <div class="flex justify-center w-full">
                <img v-if="reviewData.total_deposits > 0" src="../../assets/images/year-in-review/deposit.svg" />
                <img v-else src="../../assets/images/year-in-review/deposit1.svg" />
            </div>

            <div class="relative" style="top:-.9rem">
                <div class="rounded-3xl bg-green-2B text-gray-D9">
                    <div class="bg-green-4F px-5 py-8 rounded-3xl">
                        <p class="text-sm font-satoshi_r">Average Amount</p>
                        <p class="font-satoshi_b text-4xl mt-1">
                            ₦{{ reviewData.average_amount_deposited?
                            Number(reviewData.average_amount_deposited.toFixed(2)).toLocaleString() : 0}}</p>
                    </div>

                    <div class="px-5 py-3 text-sm font-satoshi_r">
                        <p v-if="reviewData.total_deposits <= 0">
                            Your wallet's feeling a bit lonely. Give it some love with a deposit! Don't worry, it won't
                            bite.
                        </p>
                        <p v-else-if="reviewData.total_deposits < 1000">
                            Your 2024 deposits were under ₦1000 😔. Think you can 1000X your total deposits next year? We
                            dare you. Let's see what you're made of.
                        </p>
                        <p v-else>
                            <span class="text-green-90 font-satoshi_b">
                                {{ reviewData.top_depositor_name? reviewData.top_depositor_name : "No one"  }}</span> sent
                            money to your wallet the most in 2024 with a total of
                            <span class="text-green-90 font-satoshi_b">₦{{ reviewData.total_amount_sent_by_top_depositor?
                                Number(reviewData.total_amount_sent_by_top_depositor.toFixed(2)).toLocaleString() : 0}}</span>
                        </p>
                    </div>
                </div>

                <!-- <div v-if="reviewData.total_deposits <= 0" class="pt-5 pb-2 px-20">
                    <a href="https://sycamoreng.onelink.me/Qthc/9274li8k" 
                    class="priority-z-index">
                        <button type="button text-sm" >
                            Start small, Dream big 
                        </button>
                    </a>
                </div> -->

                <!-- <div v-else-if="reviewData.total_deposits < 1000" class="py-5 px-20">
                    <a href="https://sycamoreng.onelink.me/Qthc/9274li8k"
                    class="priority-z-index">
                        <button type="button text-sm" >
                            Pumped! Let’s go
                        </button>
                    </a>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import SycamoreLogo from "../../svg/SycamoreLogo.vue";
import pagination from "./pagination.vue";

export default {
    components: {
        SycamoreLogo,
        pagination
    },

    props: {
        reviewData: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped>
.deposit {
    padding: 16px 0px;
    background-color: #ebecb0;
    min-height: 100vh;
    background-image: url("../../assets/images/year-in-review/deposit-bg.png");
    background-size: cover;
}

a button{
    outline: none;
    border-radius: 12px;
    height: 48px;
    color: #F5F6D9;
    font-family: 'satoshi_m', 'sans-serif';
    width: 100%;
    background-color: #22884F;
}
</style>