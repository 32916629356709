<template>
    <div class="max-w-full sm:max-w-md mx-auto inset-y-0 right-0 full-screen-height flex flex-col justify-center items-center">
        <div class="s-height shadow bg-white full-screen-height w-full relative sm:rounded-lg">
            <div class="swipeable-container" ref="swipeContainer">

                <div v-if="currentScreen !== 0" class="tap-zone left-zone" @click="goBackward"></div>
                <div v-if="currentScreen !== 0" class="tap-zone right-zone" @click="goForward"></div>

                <login v-if="currentScreen == 0"
                    @registerProp="handleProp"
                />

                <cover 
                    v-if="currentScreen == 1"
                />

                <accountDetails 
                    v-if="currentScreen == 2" :reviewData="{
                    monthly_average_balance: review.monthly_average_balance,
                    month_with_highest_balance: review.month_with_highest_balance
                }" />

                <targetSavings
                    v-if="currentScreen == 3" :reviewData="{
                    total_savings_amount: review.total_savings_amount,
                    total_savings_returns: review.total_savings_returns,
                    top_savings_category: review.top_savings_category,
                }" />

                <deposit v-if="currentScreen == 4" :reviewData="{
                    total_deposits: review.total_deposits,
                    average_amount_deposited: review.average_amount_deposited,
                    top_depositor_name: review.top_depositor_name,
                    total_amount_sent_by_top_depositor: review.total_amount_sent_by_top_depositor,
                }" />

                <transfers v-if="currentScreen == 5" :reviewData="{
                    total_outgoing_transfers: review.total_outgoing_transfers,
                    top_recipient_name: review.top_recipient_name,
                    count_of_transfers_from_top_recipient: review.count_of_transfers_from_top_recipient,
                    total_amount_sent_by_top_recipient: review.total_amount_sent_by_top_recipient
                }" />

                <loans v-if="currentScreen == 6" :reviewData="{
                    loans_collected: review.loans_collected,
                    loans_repaid: review.loans_repaid,
                    has_been_rejected_on_all_loans: review.has_been_rejected_on_all_loans,
                }" />
                    
                <investment v-if="currentScreen == 7" :reviewData="{
                    total_amount_invested: review.total_amount_invested,
                    total_investment_returns: review.total_investment_returns
                }" />

                <loanFriends v-if="currentScreen == 8" :reviewData="{
                    total_amount_lent: review.total_amount_lent,
                    favorite_beneficiary: review.favorite_beneficiary,
                    total_amount_borrowed: review.total_amount_borrowed,
                    favorite_lender: review.favorite_lender
                }" />

                <billPayments v-if="currentScreen == 9" 
                    :reviewData="{
                        count_of_bill_payments: review.count_of_bill_payments,
                        amount_spent_on_bill_payments: review.amount_spent_on_bill_payments,
                        top_biller_category: review.top_biller_category,
                        total_amount_paid_for_top_biller_category: review.total_amount_paid_for_top_biller_category
                    }" 
                />

                <walletInterest v-if="currentScreen == 10" :reviewData="{
                    wallet_interest: review.wallet_interest,
                    cashback: review.cashback
                }" />

                <financialRecommendations v-if="currentScreen == 11"
                    :reviewData="{
                        total_amount_invested: review.total_amount_invested,
                        total_outgoing_transfers: review.total_outgoing_transfers,
                        total_savings_amount: review.total_savings_amount,
                        has_more_than_two_due_schedules: review.has_more_than_two_due_schedules,
                        total_deposits: review.total_deposits,
                        total_amount_lent: review.total_amount_lent,
                        total_amount_borrowed: review.total_deposits
                    }"
                />
                <superStar v-if="currentScreen == 12" />
            </div>
        </div>
    </div>
</template>

<script>
import login from "../components/yearInReview/login.vue";
import cover from "../components/yearInReview/cover.vue";
import accountDetails from "../components/yearInReview/AccountBalance.vue";
import targetSavings from "../components/yearInReview/TargetSavings";
import deposit from "../components/yearInReview/Deposit";
import transfers from "../components/yearInReview/Transfers";
import loans from "../components/yearInReview/Loans";
import investment from "../components/yearInReview/Investment";
import loanFriends from "../components/yearInReview/LoanFriends";
import billPayments from "../components/yearInReview/BillPayments";
import walletInterest from "@/components/yearInReview/WalletInterest";
import financialRecommendations from "../components/yearInReview/FinancialRecommendations.vue";
import superStar from "../components/yearInReview/Superstar.vue";

import Hammer from "hammerjs";

export default {
    components: {
        login,
        cover,
        accountDetails,
        targetSavings,
        deposit,
        transfers,
        loans,
        investment,
        loanFriends,
        billPayments,
        walletInterest,
        financialRecommendations,
        superStar,
    },

    data() {
        return {
            currentScreen: 0,
            loadingStatus: false,
            review: {},

            imageConfig: [
                'wallet.svg', 'coin.svg',
                'coin2.svg', 'bills.svg',
                'bills1.svg', 'round-up.svg',
                'deposit.svg', 'deposit1.svg',
                'recommendation-illustration.svg',
                'money-box.svg', 'money-box1.svg',
                'money-glass.svg', 'money-glass1.svg',
                'money-sack.svg', 'money-sack1.svg',
                'superstar-illustration.svg',
                'savings-box.svg', 'savings-box1.svg',
                'plane.svg', 'plane1.svg',
                'money-can.svg', 'money-can1.svg',
                'acct-balance-bg.png', 'bills-payment-bg.png',
                'cover-bg.png', 'deposit-bg.png',
                'fin-recommendation-bg.png',
                'investment-bg.png', 'loan-friends-bg.png',
                'loans-bg.png', 'target-savings-bg.png',
                'transfers-bg.png', 'wallet-interest-bg.png'
            ]
        }
    },

    methods: {
        handleProp(reviewData){
            this.review = reviewData
            this.currentScreen = 1
        },

        goForward() {
            if (this.currentScreen < 12 && this.currentScreen > 0) {
                this.currentScreen++;
            }
        },
        goBackward() {
            if (this.currentScreen > 1) {
                this.currentScreen--;
            }
        },

        addGoogleAnalyticsScript() {
            if (!document.getElementById('google-analytics')) {
                const script = document.createElement('script');
                script.id = 'google-analytics';
                script.async = true;
                script.src = 'https://www.googletagmanager.com/gtag/js?id=G-PQ6ZNRBF4X';
                document.head.appendChild(script);

                const inlineScript = document.createElement('script');
                inlineScript.text = `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-PQ6ZNRBF4X');
                `;
                document.head.appendChild(inlineScript);
            }
        }
    },

    mounted() {
        const swipeContainer = this.$refs.swipeContainer;
        const hammer = new Hammer(swipeContainer);

        hammer.on("swipeleft", this.goForward);
        hammer.on("swiperight", this.goBackward);

        this.$preloadImages(this.imageConfig);

        this.addGoogleAnalyticsScript();
    },

    beforeDestroy() {
        const gaScript = document.getElementById('google-analytics');
        if (gaScript) {
            gaScript.remove();
        }
    }
};
</script>

<style>
.swipeable-container {
    width: 100%;
    height: 100vh;
    cursor: pointer;
    position: relative;
}

.tap-zone {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  cursor: pointer;
  z-index: 10;
}

.left-zone {
  left: 0;
}

.right-zone {
  right: 0;
}

.priority-z-index{
    position: relative;
    z-index: 20;
}
</style>