<template>
<svg width="320" height="2" viewBox="0 0 328 2" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="22.5455" height="2" fill="white" :fill-opacity="tab==2? '1' : '0.5'"/>
<rect width="22.5455" height="2" transform="translate(30.5454)" fill="white" :fill-opacity="tab==3? '1' : '0.5'"/>
<rect width="22.5455" height="2" transform="translate(61.0908)" fill="white" :fill-opacity="tab==4? '1' : '0.5'"/>
<rect width="22.5455" height="2" transform="translate(91.6365)" fill="white" :fill-opacity="tab==5? '1' : '0.5'"/>
<rect width="22.5455" height="2" transform="translate(122.182)" fill="white" :fill-opacity="tab==6? '1' : '0.5'"/>
<rect width="22.5455" height="2" transform="translate(152.727)" fill="white" :fill-opacity="tab==7? '1' : '0.5'"/>
<rect width="22.5455" height="2" transform="translate(183.273)" fill="white" :fill-opacity="tab==8? '1' : '0.5'"/>
<rect width="22.5455" height="2" transform="translate(213.818)" fill="white" :fill-opacity="tab==9? '1' : '0.5'"/>
<rect width="22.5455" height="2" transform="translate(244.364)" fill="white" :fill-opacity="tab==10? '1' : '0.5'"/>
<rect width="22.5455" height="2" transform="translate(274.909)" fill="white" :fill-opacity="tab==11? '1' : '0.5'"/>
<rect width="22.5455" height="2" transform="translate(305.455)" fill="white" :fill-opacity="tab==12? '1' : '0.5'"/>
</svg>
</template>

<script>
export default {
    props:{
        tab: {
            type: Number,
            Required: true
        }
    }
}
</script>