<template>
    <div class="target-savings">
        <div class="">
            <div class="mt-1 md:mt-20">
                <pagination :tab="3" class="m-auto"/>
            </div>
            <div class="flex mt-5 mx-9 priority-z-index">
                <SycamoreLogo />
            </div>
        </div>

        <div v-if="reviewData.total_savings_amount > 0" class="mx-9">
            <div class="mt-9 text-center">
                <p class="text-lg font-satoshi_r">You saved a total of</p>
                <p class="text-4xl font-satoshi_b">₦{{ reviewData.total_savings_amount? Number(reviewData.total_savings_amount.toFixed(2)).toLocaleString() : 0}}</p>
            </div>
            <div class="flex justify-center w-full mt-8">
                <img src="../../assets/images/year-in-review/savings-box.svg" />
            </div>
            <div :class="reviewData.total_savings_amount >= 50000? 'mt-8' : 'mt-12'">
                <p v-if="reviewData.total_savings_amount >= 50000" class="pr-8">
                    Sycamore paid you <span class="font-satoshi_b">₦{{ reviewData.total_savings_returns? Number(reviewData.total_savings_returns.toFixed(2)).toLocaleString() : 0}}</span> in
                    returns on your savings
                </p>

                <p class="pr-10 pt-4">
                    Your top savings category in 2024 was
                    <span class="font-satoshi_b">{{reviewData.top_savings_category? reviewData.top_savings_category : "No category"}}</span>
                </p>

                <!-- <div v-if="reviewData.total_savings_amount < 50000" class="mt-5 px-14">
                    <a href="#">
                        <button type="submit" class="flex text-sm items-center justify-center gap-2" >
                            Save Now
                            <img src="../../assets/images/year-in-review/PiggyBank.svg" alt="">
                        </button>
                    </a>
                </div> -->
            </div>
        </div>

        <div v-else class="mx-9">
            <div class="mt-9 text-center">
                <p class="text-lg font-satoshi_r" style="line-height: 22px;">You’ve never created a <br/>
                    savings plan 😔.</p>
            </div>
            <div class="flex justify-center mt-9 w-full">
                <img src="../../assets/images/year-in-review/savings-box1.svg" />
            </div>
            <div class="mt-8" tyle="line-height: 22px;">
                <p class="">
                    Whether it's a vacation or a new car,<br/> we've got you covered.
                </p>
                <p class="mt-3">
                    Start your savings journey today and earn as much as <span class="font-satoshi_b">20%</span> interest on your savings!
                </p>
            </div>

            <!-- <div class="mt-7 mb-5 px-14">
                <a href="https://sycamoreng.onelink.me/Qthc/rlvl9z0s" class="priority-z-index">
                    <button type="submit" class="flex text-sm items-center justify-center gap-2" >
                        Save Now
                        <img src="../../assets/images/year-in-review/PiggyBank.svg" alt="">
                    </button>
                </a>
            </div> -->
        </div>
    </div>
</template>

<script>
import SycamoreLogo from "../../svg/SycamoreLogo.vue";
import pagination from "./pagination.vue";

export default {
    components: {
        SycamoreLogo,
        pagination
    },

    props: {
        reviewData: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped>
.target-savings {
    padding: 16px 0px;
    color: #1e4457;
    background-color: #99c8fe;
    min-height: 100vh;
    background-image: url("../../assets/images/year-in-review/target-savings-bg.png");
    background-size: cover;
}

a button{
    outline: none;
    border-radius: 12px;
    height: 48px;
    color: #99c8fe;
    font-family: 'satoshi_m', 'sans-serif';
    width: 100%;
    background-color: #1E4457;
}
</style>