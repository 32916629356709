<template>
    <div class="loans">
        <div>
            <div class="mt-1 md:mt-20">
                <pagination :tab="6" class="m-auto" />
            </div>

            <div class="flex mt-5 mx-9 priority-z-index">
                <SycamoreLogo />
            </div>
        </div>

        <div class="mt-11 mx-9">
            <div class="">
                <div v-if="reviewData.loans_collected > 0">
                    <div>
                        <p class="font-satoshi_r text-lg">Sycamore disbursed</p>
                        <p class="font-satoshi_b text-40 text-orange-B3">
                            ₦{{ reviewData.loans_collected?
                            Number(reviewData.loans_collected.toFixed(2)).toLocaleString() : 0 }}</p>
                        <p class="font-satoshi_r text-lg mt-1">to you in loans in 2024.</p>
                    </div>
                    <div class="mt-9">
                        <p class="font-satoshi_r text-lg">You paid back</p>
                        <p class="font-satoshi_b text-40 text-orange-B3">₦{{ reviewData.loans_repaid?
                            Number(reviewData.loans_repaid.toFixed(2)).toLocaleString() :0 }}</p>
                    </div>
                </div>

                <div v-else-if="reviewData.has_been_rejected_on_all_loans">
                    <div>
                        <p class="font-satoshi_b text-lg">We understand that loan rejections can be disappointing. </p>
                        <p class="font-satoshi_r text-lg pt-1 text-orange-B3">Don't worry, we can work together to strengthen your credit profile. In the meantime, explore other Sycamore features to manage your finances effectively.</p>
                    </div>
                </div>

                <div v-else-if="reviewData.loans_collected <= 0 && !reviewData.has_been_rejected_on_all_loans">
                    <div>
                        <p class="font-satoshi_b text-lg">You did not apply for a loan this year 😔 </p>
                        <p class="font-satoshi_r text-lg pt-1 text-orange-B3">Feeling a little strapped for cash? Our business and salary loans are tailored to meet your needs..</p>
                    </div>
                </div>
            </div>

            <!-- <div class="flex flex-col justify-between w-full mt-10">
                <div v-if="reviewData.loans_collected <= 0 && !reviewData.has_been_rejected_on_all_loans" class="mb-20 px-16">
                    <a href="https://sycamoreng.onelink.me/Qthc/kcclh24q" class="priority-z-index"">
                        <button type="button" class="text-sm" >
                            Apply for a Loan
                        </button>
                    </a>
                </div>

                <div v-if="reviewData.has_been_rejected_on_all_loans" class="mb-20 px-16">
                    <a href="https://sycamoreng.onelink.me/Qthc/rlvl9z0s" class="priority-z-index"">
                        <button type="button" class="text-sm" >
                            Save with Sycamore
                        </button>
                    </a>
                </div>
            </div> -->
        </div>

        <div>
            <img v-if="reviewData.loans_collected <= 0" class="m-auto w-full" src="../../assets/images/year-in-review/money-sack1.svg" />
            <img v-else class="m-auto w-full" src="../../assets/images/year-in-review/money-sack.svg" />
        </div>
    </div>
</template>

<script>
import SycamoreLogo from "../../svg/SycamoreLogo.vue";
import pagination from "./pagination.vue";

export default {
    components: {
        SycamoreLogo,
        pagination
    },

    props: {
        reviewData: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped>
.loans {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 0px 0px;
    background-color: #ff6b33;
    color: #f6f7ff;
    min-height: 100vh;
    background-image: url("../../assets/images/year-in-review/loans-bg.png");
    background-size: cover;
}

a button{
    outline: none;
    border-radius: 12px;
    height: 48px;
    color: #EF6530;
    font-family: 'satoshi_m', 'sans-serif';
    width: 100%;
    background-color: #FFF7D8;
}
</style>