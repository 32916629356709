import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Loan from '../views/contract/Loan.vue';
import Investment from '../views/contract/Investment.vue';
import TargetContribution from '../views/contract/TargetContribution.vue';
import Statement from '../views/statement.vue';
import Verify from '../views/email/Verify.vue';
import ResetPassword from '../views/email/ResetPassword.vue';
import Migration from '../views/migrations/index.vue'
import CustomerMigration from '../views/migrations/customers/index.vue'
import LoanMigration from '../views/migrations/loan/index.vue'
import InvestmentMigration from '../views/migrations/investment/index.vue'
import StaffMigration from '../views/migrations/staff/index.vue'
import profileUpdate from '../views/profileUpdate.vue'
import directDebit from '../views/mandate/direct_debit'
import monoDirectDebit from '../views/mandate/monoDirectDebit.vue'
import ProofOfInvestment from '../views/ProofOfInvestment.vue'
import CustomerYearInReview from '../views/YearInReview.vue'


import testDataCP from '../views/test/index.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/profile-update',
    name: 'profileUpdate',
    component: profileUpdate
  },
  {
    path: '/mandate/direct_debit',
    name: 'directDebit',
    component: directDebit
  },
  {
    path: '/mandate/mono/direct_debit',
    name: 'monoDirectDebit',
    component: monoDirectDebit
  },
  {
    path: '/contract/:offer_link',
    name: 'OfferLink',
    component: Loan
  },
  {
    path: '/target/contract/:offer_link',
    name: 'TCOfferLink',
    component: TargetContribution
  },
  {
    path: '/investment/contract/:offer_link',
    name: 'InvestmentOfferLink',
    component: Investment
  },
  {
    path: '/investment/proof/:offer_link',
    name: 'ProofOfInvestment',
    component: ProofOfInvestment
  },
  {
    path: '/statement/:token',
    name: 'Statement',
    component: Statement
  },
  {
    path: '/confirm_email',
    name: 'VerifyEmail',
    component: Verify
  },
  {
    path: '/reset_password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: "/migration/",
    component: Migration,
    children: [
      { path: "customers", component: CustomerMigration },
      { path: "loans", component: LoanMigration },
      { path: "investments", component: InvestmentMigration },
      { path: "staff", component: StaffMigration }
    ]
  },
  {
    path: '/year-in-review',
    name: 'CustomerYearInReview',
    component: CustomerYearInReview
  },

  // for test purpose only
  {
    path: '/test',
    name: 'testDataCP',
    component: testDataCP
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
