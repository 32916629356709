export default {
    install(Vue, options = {}) {
      const preloadImages = (images) => {
        images.forEach((image) => {
          const img = new Image();
          img.src = require(`@/assets/images/year-in-review/${image}`);
        });
      };
    
      Vue.prototype.$preloadImages = (config) => {
        if (config) {
          preloadImages(config);
        }
      };
    },
};
