<template>
    <div class="walletInterest pt-4">
        <div class="">
            <div class="mt-1 md:mt-24">
                <pagination :tab="10" class="m-auto" />
            </div>

            <div class="flex mt-5 mx-9 priority-z-index">
                <SycamoreLogo />
            </div>

            <div class="mt-12 mx-8 pr-4">
                <p class="font-satoshi_r text-lg">You earned</p>
                <p class="font-satoshi_b text-4xl mt-1">₦{{ reviewData.wallet_interest?
                    Number(reviewData.wallet_interest.toFixed(2)).toLocaleString() : 0 }}</p>
                <p v-if="reviewData.wallet_interest > 0" class="font-satoshi_r mt-2 text-lg">
                    In wallet interest from Sycamore in 2024
                </p>

                <div v-if="reviewData.wallet_interest <= 0" >
                    <p class="font-satoshi_r mt-3 text-lg">
                        Every little bit counts! Create a savings plan to earn cashback on all your bill payments.
                    </p>

                    <!-- <div class="mt-10 px-10">
                        <a href="https://sycamoreng.onelink.me/Qthc/9274li8k" class="priority-z-index">
                            <button type="button" class="text-sm">
                                Create Savings Plan
                            </button>
                        </a>
                    </div> -->
                </div>

                <div v-else-if="reviewData.wallet_interest <= 1000">
                    <p class="font-satoshi_r pt-3 text-lg">
                        You earned less than ₦1000 as interest on your wallet balance. Get up to <span class="font-satoshi_b">10% p.a</span> as interest on your wallet balance.
                    </p>
                    <p class="font-satoshi_r pt-3 text-lg">
                        Grow your wallet balance to maximize your interest earnings.
                    </p>
                    <!-- <div class="mt-10 px-16">
                        <a href="https://sycamoreng.onelink.me/Qthc/9274li8k" class="priority-z-index">
                            <button type="button" class="text-sm">
                                Fund Wallet
                            </button>
                        </a>
                    </div> -->
                </div>

                <p v-else class="font-satoshi_r mt-3">
                    You earned <span class="font-satoshi_b">₦{{ reviewData.cashback?
                    Number(reviewData.cashback.toFixed(2)).toLocaleString() :0 }}</span> as cash-back
                    on your bills from Sycamore in 2024.
                </p>
            </div>
        </div>

        <div class="flex justify-end w-full mt-10 pl-10">
            <img v-if="reviewData.wallet_interest <= 0" src="../../assets/images/year-in-review/money-can1.svg" />
            <img v-else src="../../assets/images/year-in-review/money-can.svg" />
        </div>
    </div>
</template>

<script>
import SycamoreLogo from "../../svg/SycamoreLogo.vue";
import pagination from "./pagination.vue";

export default {
    components: {
        SycamoreLogo,
        pagination
    },

    props: {
        reviewData: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped>
.walletInterest {
    background-color: #db633d;
    min-height: 100vh;
    background-image: url("../../assets/images/year-in-review/wallet-interest-bg.png");
    color: #fff7d8;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: cover;
}

a button{
    outline: none;
    border-radius: 12px;
    height: 48px;
    color: #db633d;
    font-family: 'satoshi_m', 'sans-serif';
    width: 100%;
    background-color: #FFF7D8;
}
</style>
