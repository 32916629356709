<template>
    <div class="recommendations pt-1">
        <div class="mt-4 md:mt-24"">
            <pagination :tab="11" class="m-auto" />
        </div>
        <div class="flex mt-5 mx-9 priority-z-index">
            <SycamoreLogo />
        </div>
        <div class="mt-11 text-left text-lg mx-8">
            <p class="font-satoshi_b">Our Muse says...</p>
            <div v-if="FinancialRecommendation == 'saver'">
                <p class="font-satoshi_r mt-1">
                    The Lion 🦁 is a force of nature. With consistent contributions, you're a roaring success!
                </p>
                <p class="font-satoshi_r mt-3">
                    You're a saving beast! Ready to unleash your investment power? Our experts can help you grow your wealth
                </p>
            </div>

            <div v-else-if="FinancialRecommendation == 'crusher'">
                <p class="font-satoshi_r mt-1">
                    You're the Elephant 🐘 – The Financial All-Rounder. Wise and balanced, the Elephant masters all aspects of their finances. You're managing savings, investments, and even loans with grace.
                </p>
                <p class="font-satoshi_r mt-3">
                    Challenge yourself to break your own records. Higher savings, bigger investments – let's keep the momentum going!
                </p>
            </div>

            <div v-else-if="FinancialRecommendation == 'defaulter'">
                <p class="font-satoshi_r mt-1">
                    You're the Hedgehog 🦔 – The Loan Repayment Warrior. Small but mighty, the Hedgehog conquers challenges, even loan repayments. Ready for a fresh start?
                </p>
                <p class="font-satoshi_r mt-3">
                    Life throws curveballs. Let's get back on track. We're here to help!
                </p>
            </div>

            <div v-else-if="FinancialRecommendation == 'spender'">
                <p class="font-satoshi_r mt-1">
                    You're the Parrot 🦜 – The Spender. The Parrot loves the good life and lives in the moment. But even the most vibrant bird needs a nest egg. 
                </p>
                <p class="font-satoshi_r mt-3">
                    Enjoy the moment, but plan for the future. A little saving now can cushion your dreams tomorrow
                </p>
            </div>

            <div v-else-if="FinancialRecommendation == 'raven'">
                <p class="font-satoshi_r mt-1">
                    You're the The Raven 🐦‍⬛ - The Curious One. Intelligent and curious, the Raven is eager to explore. You've shown interest, but perhaps you're still discovering the app's potential.
                </p>
                <p class="font-satoshi_r mt-3">
                    Explore our tools for saving, investing, and borrowing. Discover how we can help you achieve your financial goals.
                </p>
            </div>

        </div>

        <div class="flex justify-start w-full mt-8 relative">
            <img src="../../assets/images/year-in-review/recommendation-illustration.svg" />
        </div>

        <div class="flex justify-center items-center mx-9 mb-10">
            <!-- <a href="https://sycamoreng.onelink.me/Qthc/i7tv3iac" v-if="FinancialRecommendation == 'saver'">
                <button class="w-56 font-satoshi_m bg-gray-D8 rounded-xl text-gray-28">
                    Invest Now
                </button>
            </a> -->
            <a href="https://sycamoreng.onelink.me/Qthc/9lrl1bvq" v-if="FinancialRecommendation == 'crusher' || FinancialRecommendation == 'raven'">
                <button class="w-56 font-satoshi_m bg-gray-D8 rounded-xl text-gray-28">
                    Transact Now
                </button>
            </a>
            <!-- <a href="https://sycamoreng.onelink.me/Qthc/kcclh24q" v-if="FinancialRecommendation == 'defaulter'">
                <button class="w-56 font-satoshi_m bg-gray-D8 rounded-xl text-gray-28">
                    Repay Loan Now
                </button>
            </a> -->
            <!-- <a href="https://sycamoreng.onelink.me/Qthc/rlvl9z0s" v-if="FinancialRecommendation == 'spender'">
                <button class="w-56 font-satoshi_m bg-gray-D8 rounded-xl text-gray-28">
                    Create Savings Plan
                </button>
            </a> -->
        </div>
    </div>
</template>

<script>
import SycamoreLogo from "../../svg/SycamoreLogo.vue";
import pagination from "./pagination.vue";

export default {
    components: {
        SycamoreLogo,
        pagination
    },

    props: {
        reviewData: {
            type: Object,
            required: true,
        }
    },

    computed: {
        FinancialRecommendation (){
            if (this.reviewData.total_amount_invested >= 1000000 && this.reviewData.total_savings_amount >= 1000000) {
                return "crusher";

            } else if (this.reviewData.total_savings_amount >= 1000000) {
                return "saver";

            } else if (this.reviewData.total_savings_amount < this.reviewData.total_outgoing_transfers &&
                this.reviewData.total_deposits >= 10000) {
                return "spender";

            } else if (this.reviewData.has_more_than_two_due_schedules) {
                return "defaulter";

            } else {
                return "raven";

            }
        }
    }
};
</script>

<style scoped>
.recommendations {
    background-color: #213a3f;
    color: #fff7d8;
    min-height: 100vh;
    background-image: url("../../assets/images/year-in-review/fin-recommendation-bg.png");
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: cover;
}
</style>