<template>
  <div class="bill-payment">
    <div class="mt-1">
      <pagination :tab="12" class="m-auto" />
    </div>

    <div class="flex mt-5 mx-9 priority-z-index">
      <SycamoreLogo />
    </div>

    <div class="mt-12 mx-9">
      <p class="font-satoshi_r text-lg">You are a</p>
      <p class="font-satoshi_b text-4xl mt-1">Superstar 🌟</p>
      <p class="font-satoshi_r text-xl italic mt-4">
        <span class="font-satoshi_b">Share</span> your 2024 money roundup with
        friends.
      </p>
    </div>

    <div class="flex justify-center w-full mt-8">
      <img
        src="../../assets/images/year-in-review/superstar-illustration.svg"
      />
    </div>

    <div class="text-xxs mt-5 text-center mx-9">
      <div class="flex justify-center items-center">
        <ShareNetwork
            network="whatsapp"
            url="https://misc.sycamore.ng/year-in-review"
            title="Wow, what a year it's been!"
            description="I just checked out my 2024 financial summary on Sycamore. It's pretty cool! You can check yours out too when you click on this link: "
        >
            <button
                class="py-3 w-56 font-satoshi_m bg-gray-D8 rounded-xl text-gray-28 text-sm"
                style="z-index: 20; position: relative;"
            >
                <div class="flex space-x-2 justify-center">
                    <Share />
                    <p>Share this story</p>
                </div>
            </button>
        </ShareNetwork>
      </div>
      <div class="mt-8">
        <p class="font-satoshi_b">
          The information in your "Year in Review" is personal and sensitive.
        </p>
        <p class="mt-4">
          We recommend sharing it only with people you trust. Sharing this data
          on social media could put your privacy and security at risk.
        </p>
      </div>
    </div>
  </div>
</template>
        
<script>
import Share from "../../svg/Share.vue";
import SycamoreLogo from "../../svg/SycamoreLogo.vue";
import pagination from "./pagination.vue";

export default {
  components: {
    Share,
    SycamoreLogo,
    pagination
  }
};
</script>
        
<style scoped>
.bill-payment {
  padding: 1rem 0rem;
  color: #fff7d8;
  background-color: #7a44be;
  min-height: 100vh;
  background-image: url("../../assets/images/year-in-review/loan-friends-bg.png");
  background-size: cover;
}
</style>