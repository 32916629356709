<template>
  <svg width="92" height="25" viewBox="0 0 92 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M37.915 13.3753C37.9403 14.2317 37.6249 14.9496 36.9564 15.5415C36.2879 16.1335 35.5059 16.4358 34.5978 16.4358C33.4374 16.4358 32.3905 15.9572 31.4697 14.9874L32.6175 13.5894C33.2103 14.2569 33.8158 14.597 34.4338 14.6221C34.7491 14.6347 35.014 14.5592 35.2537 14.4206C35.5438 14.2191 35.7077 13.9169 35.733 13.5138C35.7582 13.0227 35.4681 12.6322 34.85 12.33L33.3995 11.6373C32.3653 11.1461 31.8481 10.3023 31.8229 9.10578C31.8103 8.28714 32.113 7.60704 32.7563 7.06547C33.3617 6.5491 34.0806 6.29721 34.9131 6.29721C35.9222 6.29721 36.8681 6.68764 37.7258 7.44331L36.5276 8.70275C36.1618 8.38789 35.9348 8.21157 35.8465 8.1486C35.5816 7.98487 35.2789 7.89671 34.9636 7.89671C34.6356 7.89671 34.3834 7.99746 34.1689 8.18638C33.9671 8.3753 33.8662 8.63978 33.8662 8.97983C33.8662 9.44583 34.1311 9.79847 34.6608 10.0378L36.2123 10.7431C37.3096 11.2972 37.8772 12.1536 37.915 13.3753Z" fill="#FFFAFF"/>
<path d="M46.2271 9.87402L42.1783 18.7027H40.2107L41.5981 15.7053L38.8989 9.87402H41.0179L42.6072 13.5138L44.1838 9.87402H46.2271Z" fill="#FFFAFF"/>
<path d="M51.4867 15.869C50.9065 16.1587 50.3767 16.2972 49.8848 16.2972C48.9641 16.2972 48.1694 15.9698 47.5136 15.3274C46.8451 14.6851 46.5297 13.9043 46.5424 13.0101C46.555 12.1033 46.8829 11.335 47.5388 10.6927C48.1821 10.0504 48.9515 9.7355 49.847 9.7355C50.3011 9.7355 50.8308 9.86145 51.411 10.1133V11.7002C51.2344 11.6247 51.0452 11.5617 50.8686 11.4987C50.5785 11.398 50.3389 11.3476 50.1245 11.3602C49.6326 11.3602 49.2542 11.5239 48.9641 11.8388C48.674 12.1536 48.5352 12.5315 48.5352 12.9849C48.5352 13.4383 48.6866 13.8161 48.9641 14.1184C49.2668 14.4332 49.6578 14.597 50.1371 14.597C50.5785 14.597 51.0326 14.4332 51.4993 14.1184V15.869H51.4867Z" fill="#FFFAFF"/>
<path d="M59.66 16.1964H57.7555V15.6045C57.2005 16.1209 56.5446 16.398 55.813 16.4106C54.9553 16.4357 54.2238 16.1083 53.631 15.4534C53.0382 14.8111 52.7354 14.0428 52.7228 13.1486C52.7102 12.2418 52.9877 11.4483 53.5679 10.7682C54.1733 10.0755 54.9049 9.73548 55.7752 9.74808C56.532 9.76067 57.2257 10.0378 57.8437 10.6045V9.88662H59.66V16.1964ZM57.9194 13.0604C57.9194 12.607 57.7555 12.2166 57.4401 11.8891C57.1248 11.5617 56.7338 11.4105 56.2671 11.4231C55.7878 11.4357 55.4094 11.5995 55.1067 11.9269C54.8166 12.2418 54.6652 12.6322 54.6526 13.0856C54.64 13.539 54.804 13.9168 55.1319 14.2317C55.4599 14.5466 55.8509 14.7103 56.2923 14.7229C56.7338 14.7229 57.1248 14.5592 57.4401 14.2317C57.7681 13.9043 57.9194 13.5138 57.9194 13.0604Z" fill="#FFFAFF"/>
<path d="M70.6838 16.1965H68.7792V12.5818C68.7792 11.801 68.4386 11.4106 67.7701 11.4106C67.48 11.4106 67.2404 11.5239 67.0512 11.738C66.862 11.9647 66.7737 12.2418 66.7737 12.5818V16.1965H64.8565V12.6448C64.8565 11.864 64.579 11.4735 64.0241 11.4609C63.7213 11.4483 63.4565 11.5743 63.2294 11.8262C63.0024 12.0781 62.8889 12.3677 62.8889 12.67V16.1965H60.9717V9.87403H62.8763V10.6675C63.179 10.34 63.4312 10.1259 63.6078 10.0252C63.8601 9.87404 64.188 9.79847 64.5664 9.79847C65.134 9.79847 65.5755 9.89922 65.8782 10.0881C66.0295 10.1889 66.2692 10.4282 66.5845 10.806C67.0386 10.0504 67.7071 9.69772 68.59 9.7355C69.2711 9.76069 69.8009 10.0126 70.1792 10.4786C70.5324 10.9194 70.709 11.4609 70.709 12.1159V16.1965H70.6838Z" fill="#FFFAFF"/>
<path d="M78.9456 13.0982C78.9456 14.068 78.5924 14.874 77.8987 15.5289C77.205 16.1713 76.3599 16.4987 75.3761 16.4987C74.4175 16.4987 73.585 16.1587 72.9039 15.4786C72.2102 14.7985 71.8696 13.9798 71.8696 13.0352C71.8696 12.0781 72.2354 11.2846 72.9544 10.6171C73.6607 9.96219 74.5058 9.63474 75.4896 9.63474C76.4356 9.63474 77.2428 9.97479 77.9239 10.6549C78.6176 11.335 78.9582 12.141 78.9456 13.0982ZM77.0284 13.0604C77.0032 12.5818 76.8266 12.1914 76.5113 11.8766C76.1959 11.5617 75.8175 11.4106 75.3635 11.4357C74.9346 11.4483 74.5688 11.6247 74.2661 11.9395C73.9634 12.267 73.8247 12.6448 73.8373 13.073C73.8499 13.5138 74.0139 13.8791 74.3166 14.1939C74.6193 14.5088 74.9977 14.6599 75.4265 14.6599C75.868 14.6599 76.259 14.4962 76.5743 14.1813C76.9023 13.8665 77.0536 13.4886 77.0284 13.0604Z" fill="#FFFAFF"/>
<path d="M84.407 11.5365C84.2304 11.4987 84.0412 11.4861 83.852 11.4861C83.4231 11.4861 83.0952 11.5743 82.8682 11.7506C82.4772 12.0529 82.2753 12.5063 82.2753 13.0856V16.2091H80.3582V9.88663H82.2753V10.7431C82.5528 10.3652 82.8682 10.1007 83.2213 9.92442C83.5997 9.7481 83.9907 9.67253 84.4196 9.68512V11.5365H84.407Z" fill="#FFFAFF"/>
<path d="M91.9874 13.4509H87.4467C87.4972 13.8287 87.6611 14.131 87.9134 14.3703C88.1783 14.6095 88.5062 14.7229 88.8972 14.7229C89.4774 14.7229 89.9567 14.3828 90.3351 13.7027L91.7226 14.6095C90.9532 15.7808 89.9819 16.3476 88.8342 16.3224C87.8629 16.2972 87.0683 15.9446 86.4377 15.2519C85.8196 14.5718 85.5295 13.7531 85.5421 12.8085C85.5548 11.9143 85.8322 11.1839 86.3746 10.5919C86.9548 9.96219 87.7116 9.63474 88.6702 9.63474C89.7045 9.63474 90.5243 9.96219 91.1171 10.6171C91.7352 11.2972 92.0253 12.2418 91.9874 13.4509ZM89.9693 12.2292C89.9189 11.4357 89.5027 11.0453 88.708 11.0327C88.3422 11.0327 88.0395 11.1587 87.7999 11.4231C87.5855 11.6624 87.4593 11.9269 87.4467 12.2418H89.9693V12.2292Z" fill="#FFFAFF"/>
<path d="M16.4726 10.529H6.9371C5.66318 10.529 4.62891 9.49622 4.62891 8.22418V6.92695C4.62891 5.65491 5.66318 4.62216 6.9371 4.62216H14.1518C15.4257 4.62216 16.46 5.65491 16.46 6.92695V10.529H16.4726Z" fill="#FFFAFF"/>
<path d="M18.1125 20.3778H10.8978C9.62388 20.3778 8.5896 19.3451 8.5896 18.0731V14.471H18.1251C19.399 14.471 20.4333 15.5038 20.4333 16.7758V18.0731C20.4207 19.3451 19.3864 20.3778 18.1125 20.3778Z" fill="#FFFAFF"/>
<path d="M20.4206 0H4.67946C2.10639 0 0 2.09068 0 4.67254V20.3904C0 20.7683 0.302714 21.0705 0.681107 21.0705C1.0595 21.0705 1.36222 20.7683 1.36222 20.3904V5.06297C1.36222 3.01008 3.02715 1.34761 5.08308 1.34761H20.408C20.7864 1.34761 21.0891 1.04534 21.0891 0.667507C21.1017 0.302267 20.799 0 20.4206 0Z" fill="#FFFAFF"/>
<path d="M24.3686 3.92947C23.9902 3.92947 23.6875 4.23174 23.6875 4.60957V19.9244C23.6875 21.9773 22.0226 23.6398 19.9666 23.6398H4.6291C4.25071 23.6398 3.948 23.9421 3.948 24.3199C3.948 24.6977 4.25071 25 4.6291 25H20.3703C22.9433 25 25.0497 22.9093 25.0497 20.3275V4.60957C25.0497 4.24433 24.747 3.92947 24.3686 3.92947Z" fill="#FFFAFF"/>
</svg>

</template>

<script>
export default {

}
</script>

<style>

</style>